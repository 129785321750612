<template>
  <div>
    <div class="cont" ref="mengc" @click="close"></div>
    <div class="prop" ref="props">
      <div class="head">
        <i class="iconfont icon-kefu"></i>
        <p>选择客服</p>
      </div>
      <div class="kefu_cont">
        <p class="tit">王者荣耀-原神-和平精英-NBA2K OL2客服</p>
        <div class="kefu_list">
          <div class="kefu_item" v-for="item in kefufil(kefuArr, '王者荣耀')" :key="item.ID">
            <img src="@/img/女生-粉.png" />
            <div>
              <p>{{ item.NAME }}</p>
              <p>空闲</p>
            </div>
            <button @click.stop="kefuzhuan(item.MODE)">转接</button>
          </div>
        </div>
        <p class="tit">魔兽世界客服</p>
        <div class="kefu_list">
          <div class="kefu_item" v-for="item in kefufil(kefuArr, '魔兽世界')" :key="item.ID">
            <img src="@/img/女生-粉.png" />
            <div>
              <p>{{ item.NAME }}</p>
              <p>空闲</p>
            </div>
            <button @click.stop="kefuzhuan(item.MODE)">转接</button>
          </div>
        </div>
        <p class="tit">所有游戏客服</p>
        <div class="kefu_list">
          <div class="kefu_item" v-for="item in kefufil(kefuArr, '所有游戏')" :key="item.ID">
            <img src="@/img/女生-粉.png" />
            <div>
              <p>{{ item.NAME }}</p>
              <p>空闲</p>
            </div>
            <button @click="kefuzhuan(item.MODE)">转接</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'KeFuList',
  components: {},
  props: ['codes', 'isnum'],
  data() {
    return {
      token: localStorage.token,
      kefuArr: []
    }
  },
  watch: {
    isnum(e) {
      if (e === 0) {
        this.$refs.props.style.display = 'none'
        this.$refs.mengc.style.display = 'none'
      } else {
        this.$refs.props.style.display = 'block'
        this.$refs.mengc.style.display = 'block'
        this.kefulist()
      }
    }
  },
  computed: {},
  methods: {
    // 获取所有客服
    async kefulist() {
      const { data: res } = await this.$axios({
        method: 'get',
        url: 'https://web.api.yx915.com/Chatsystem/N_KEFULIST.aspx',
        params: {
          Mem: this.token
        } // get请求参数
      })
      // console.log(res)
      this.kefuArr = res
    },
    // 数据过滤
    kefufil(arr, e) {
      return arr.filter(item => {
        return item.LEIXING.indexOf(e) > -1
      })
    },
    // 客服转接
    async kefuzhuan(e) {
      const obg = {
        Lx: 'zx',
        code: this.codes,
        Mem: this.token,
        ZMem: e
      }
      if (this.isnum === 1) {
        obg.Lx = 'zx'
      } else {
        obg.Lx = 'dd'
      }
      const { data: res } = await this.$axios({
        method: 'get',
        url: 'https://web.api.yx915.com/Chatsystem/N_ZHUANKEFU.aspx',
        params: obg // get请求参数
      })
      // console.log(res)
      alert(res[0].number)
      this.close()
    },
    // 关闭客服弹出层
    close() {
      this.$emit('guanbi')
    }
  },
  created() {
    this.kefulist()
  },
  mounted() {},
  filters: {}
}
</script>
<style lang="less" scoped>
.cont {
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  display: none;
}
.prop {
  position: fixed;
  top: 200px;
  left: 540px;
  background-color: #f5f5f5;
  width: 840px;
  border-radius: 5px;
  overflow: hidden;
  display: none;
  text-align: left;
  .head {
    width: 100%;
    background-color: #fff;
    padding-left: 15px;
    box-sizing: border-box;
    i {
      display: inline-block;
      vertical-align: middle;
      font-size: 20px;
      font-weight: bold;
      color: #000;
    }
    p {
      display: inline-block;
      vertical-align: middle;
      height: 38px;
      line-height: 38px;
      font-size: 15px;
      color: #000;
      margin-left: 8px;
      font-weight: bold;
    }
  }
  .kefu_cont {
    width: 100%;
    padding: 0 15px;
    box-sizing: border-box;
    padding-bottom: 15px;
    height: 500px;
    overflow-y: auto;
    .tit {
      font-size: 13px;
      color: #1a1a1a;
      height: 40px;
      line-height: 40px;
      padding-left: 15px;
      position: relative;
    }
    .tit::after {
      content: '';
      width: 3px;
      height: 14px;
      background-color: #f00;
      position: absolute;
      left: 0;
      top: 12px;
    }
    .kefu_list {
      // display: flex;
      .kefu_item {
        width: 260px;
        height: 70px;
        padding: 10px;
        box-sizing: border-box;
        background-color: #fff;
        border-radius: 5px;
        display: inline-block;
        vertical-align: middle;
        margin-left: 5px;
        img {
          width: 50px;
          height: 50px;
          border-radius: 3px;
          display: inline-block;
          vertical-align: middle;
        }
        div {
          margin-left: 10px;
          display: inline-block;
          vertical-align: middle;
          width: 135px;
          p:nth-child(1) {
            font-size: 14px;
            color: #1a1a1a;
          }
          p:nth-child(2) {
            width: 28px;
            height: 14px;
            line-height: 14px;
            text-align: center;
            font-size: 10px;
            border-radius: 2px;
            background-color: #1bac1b;
            color: #fff;
            margin-top: 5px;
          }
        }
        button {
          background-color: #f00;
          color: #fff;
          border: 0;
          width: 40px;
          height: 20px;
          line-height: 20px;
          text-align: center;
          display: inline-block;
          vertical-align: middle;
          // margin-left: 30px;
          border-radius: 3px;
          cursor: pointer;
        }
      }
      .kefu_item:nth-child(n + 4) {
        margin-top: 15px;
      }
      .kefu_item:nth-child(3n + 1) {
        margin-left: 0;
      }
    }
  }
}
</style>
